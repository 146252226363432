import React, { Component } from 'react';

class Cv extends Component {
  state = {};
  render() {
    return (
      <div className="cv">
        <div className="container">
          <div className="row page_header">
            <div className="col-md-4 align-self-end">
              <h5>Desenvolvimento web e multimídia</h5>
              <p>viniciusofp@gmail.com</p>
            </div>
            <div className="col-md-8">
              <h2>
                Com nove anos de experiência profissional em criação de sites,
                trabalho como desenvolvedor freelancer desde 2016. Colaboro com
                profissionais de criação, design e audiovisual, áreas nas quais
                também tenho experiência.
              </h2>
            </div>
          </div>
          <hr />
          <div className="row animate__animated animate__fadeIn">
            <div className="col-md-4">
              <h3>Informações Pessoais</h3>
              <h5>Nome Completo</h5>
              <p>Vinícius de Oliveira Fernandes Pereira</p>
              <h5>Idade</h5>
              <p>28 anos</p>
              <h5>Residência</h5>
              <p>Butantã - São Paulo, SP</p>
              <h5>CNPJ</h5>
              <p>26.021.580/0001-97</p>
              <h3>Idiomas</h3>
              <h5>Inglês</h5>
              <p>Avançado</p>
              <h5>Espanhol</h5>
              <p>Iniciante</p>
            </div>
            <div className="col-md-4">
              <h3>Formação</h3>
              <h5>
                Bacharelado em Comunicação Social com habilitação em Jornalismo
              </h5>
              <p>Escola de Comunicações e Artes - USP / 2017</p>
              <h3>Habilidades e Ferramentas</h3>
              <h5>Design</h5>
              <ul>
                <li>Photoshop</li>
                <li>Lightroom</li>
                <li>Illustrator</li>
                <li>InDesign</li>
              </ul>
              <h5>Desenvolvimento Web</h5>
              <ul>
                <li>Html</li>
                <li>Css</li>
                <li>Sass</li>
                <li>Php</li>
                <li>Bootstrap</li>
                <li>Javascript</li>
                <li>JSON</li>
                <li>JQuery</li>
                <li>ReactJS</li>
                <li>NodeJS</li>
                <li>Express</li>
                <li>MongoDB</li>
                <li>GitHub</li>
                <li>Grunt</li>
                <li>MySQL</li>
                <li>Apache</li>
                <li>NGinx</li>
                <li>AWS</li>
                <li>Adobe XD</li>
                <li>Sketch</li>
              </ul>
              <h5>Vídeo</h5>
              <ul>
                <li>Premiere</li>
                <li>After Effects</li>
              </ul>
            </div>
            <div className="col-12 col-md-8">
              <h3>Experiências</h3>
              <h5>
                Autônomo
                <small> / Fevereiro 2016 - Atual</small>
              </h5>
              <p>
                <strong>Freelancer</strong>
                <br />
                Prestação de serviços em desenvolvimento web, design e
                audiovisual para projetos e empresas.
              </p>
              <h5>
                Labhacker
                <small> / Outubro 2016 - Janeiro 2017</small>
              </h5>
              <p>
                <strong>Residência Hacker</strong>
                <br />
                Projeto de cultura digital, transparência de dados e
                desenvolvimento de tecnologia.
              </p>
              <h5>
                Vaidapé
                <small> / Julho 2013 - Dezembro 2017</small>
              </h5>
              <p>
                <strong>
                  Editor, videomaker, designer e desenvolvedor web
                </strong>
                <br />
                Trabalho desde 2013 na construção e consolidação da Vaidapé,
                atuando em todas as áreas da coletivo.
              </p>
              <h5>
                Busca Vida Filmes
                <small> / Abril 2014 - Fevereiro 2016</small>
              </h5>
              <p>
                <strong>Designer e desenvolvedor front-end</strong>
                <br />
                Criação de imagens para redes sociais e sites dos filmes Elena,
                Lira Paulistana e a Vanguarda Paulista e Olmo e a Gaivota.
                Criação do poster do filme Olmo e a Gaivota. Design e
                programação dos sites dos filmes Elena e Olmo e a Gaivota.
              </p>
              <h5>
                Instituto de Matemática e Estatística da USP
                <small> / Junho 2011 - Fevereiro 2014</small>
              </h5>
              <p>
                <strong>Desenvolvedor web, diagramador e editor</strong>
                <br />
                Atualização e manutenção do site do IME-USP. Elaboração de novo
                site lançado em 2013. Edição e diagramação do jornal mensal
                Acontece no IME.
              </p>
              <h3>Prêmios e editais</h3>
              <h5>
                Melhor Fotografia
                <small> / Dezembro 2014</small>
              </h5>
              <p>
                Concurso Cultural de Comunicação do Plano Juventude Viva, da
                Secretaria de Direitos Humanos do Município de São Paulo
              </p>
              <h5>
                Prêmio Ponto de Mídia Livre
                <small> / Setembro 2014</small>
              </h5>
              <p>
                Membro da equipe técnica da Vaidapé no projeto aprovado pelo
                Prêmio Pontos de Mídia Livre do Ministério da Cultura do Governo
                Federal
              </p>
              <h5>
                PROAC 41/2015
                <small> / Dezembro 2015</small>
              </h5>
              <p>
                Proponente do projeto de Publicação de Conteúdo Cultural Quinta
                e sexta edições da revista Vaidapé aprovado pelo edital da
                Secretaria de Cultura do Estado de São Paulo
              </p>
              <h5>
                Exposição Autonomídia - Olhares Independentes Sobre
                Manifestações Populares
                <small> / Agosto 2013</small>
              </h5>
              <p>
                Vídeo selecionado para mostra no Centro Cultural da Juventude.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cv;
