import React, { Component } from 'react';
import { createMarkup } from '../includes/helperFunctions';
import Post from '../Components/Post';

class Portfolio extends Component {
  state = {
    toogleClass: ['', '', '', '', '', '', '', '', '', ''],
  };
  render() {
    const { posts } = this.props;
    const { toogleClass } = this.state;
    return (
      <div className="content">
        <div className="container">
          <div className="row posts">
            {posts.map((post, index) => {
              var colClass = 'col-12 col-md-6';
              if (index % 5 > 1) {
                colClass = 'col-12 col-md-6 col-xl-4';
              }
              const handleMouseEnter = (index) => {
                var toogleClass = [...this.state.toogleClass];
                toogleClass[index] = 'hovered';
                this.setState({ toogleClass });
              };
              const handleMouseLeave = (index) => {
                var toogleClass = [...this.state.toogleClass];
                toogleClass[index] = '';
                this.setState({ toogleClass });
              };
              return (
                <Post
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  index={index}
                  post={post}
                  toogleClass={toogleClass}
                  createMarkup={createMarkup}
                  colClass={colClass}
                />
              );
            })}
          </div>
          <div className="row page_header mt-5">
            <div className="col-md-12 col-lg-8">
              <p>
                <strong>Palavras-chave:</strong> Wordpress, E-commerce, Email
                Marketing, HTML, CSS, PHP, MySQL, JavaScript, ES6, JSON,
                ReactJS, NodeJS, MongoDB, Express, Bootstrap, Design Responsivo,
                Photoshop, Illustrator, Premiere, After Effects, InDesign, Adobe
                XD, Sketch, Arquitetura da Informação, Experiência de Usuário,
                Interface de Usuário, Amazon Web Services, GitHub
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Portfolio;
