import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Nav extends Component {
  state = {};
  render() {
    return (
      <div className="nav">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="brand">
                <Link to="/" className="hover_effect" data-hover="viniciusofp">
                  viniciusofp
                </Link>
              </div>
            </div>

            <div className="col">
              <ul>
                <li>
                  <Link to="/cv" data-hover="sobre" className="hover_effect">
                    sobre
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contato"
                    data-hover="contato"
                    className="hover_effect"
                  >
                    contato
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="col-6 text-right d-block d-sm-none">
              <i className="fa fa-bars"></i>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Nav;
