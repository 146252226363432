import React, { Component } from 'react';
import { createMarkup } from '../includes/helperFunctions';
import Post from '../Components/Post';
import { Link } from 'react-router-dom';

const filterPosts = (self, postsArray) => {
  console.log(postsArray);
  const posts = self.props.moreLoaded
    ? postsArray
    : postsArray.filter((post) => post.sticky == true);
  self.setState({ posts });
};

class Home extends Component {
  state = {
    posts: [
      { title: {}, content: {} },
      { title: {}, content: {} },
    ],
    toogleClass: ['', '', '', '', '', '', '', '', '', ''],
    moreLoaded: false,
    visited: false,
  };
  componentDidMount() {
    if (this.props.posts.length) {
      filterPosts(this, this.props.posts);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      filterPosts(this, this.props.posts);
    }
  }
  render() {
    const { moreLoaded } = this.props;
    const { posts, toogleClass } = this.state;
    return (
      <div className="content">
        <div className="container">
          <div className="row page_header">
            <div className="col-md-4 align-self-end">
              <h5>Desenvolvimento web e multimídia</h5>
              <p>viniciusofp@gmail.com</p>
            </div>
            <div className="col-md-8">
              <h2>
                Com nove anos de experiência profissional em criação de sites,
                trabalho como desenvolvedor freelancer desde 2016. Colaboro com
                profissionais de criação, design e audiovisual, áreas nas quais
                também tenho experiência.
              </h2>
            </div>
          </div>
          <div className="row posts">
            {posts.map((post, index) => {
              var colClass = 'col-12 col-lg-6';

              const handleMouseEnter = (index) => {
                var toogleClass = [...this.state.toogleClass];
                toogleClass[index] = 'hovered';
                this.setState({ toogleClass });
              };
              const handleMouseLeave = (index) => {
                var toogleClass = [...this.state.toogleClass];
                toogleClass[index] = '';
                this.setState({ toogleClass });
              };
              return (
                <Post
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  index={index}
                  post={post}
                  toogleClass={toogleClass}
                  createMarkup={createMarkup}
                  colClass={colClass}
                />
              );
            })}
          </div>
          {moreLoaded ? null : (
            <div className="row show_more">
              <div className="col-12">
                <button
                  className="button button--nina button--text-thick button--text-upper button--size-s"
                  data-text="Ver mais trabalhos"
                  onClick={this.props.loadMore}
                >
                  {'Ver mais trabalhos'.split('').map((char) => {
                    if (char === ' ') {
                      return ' ';
                    }
                    return <span>{char}</span>;
                  })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Home;
