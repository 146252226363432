import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ColorThief from 'colorthief';
class Post extends Component {
  constructor(props) {
    super(props);

    // Image element reference
    this.imgRef = React.createRef();
  }
  state = { color: 'rgba(0,0,0)' };
  render() {
    const {
      index,
      post,
      colClass,
      toogleClass,
      createMarkup,
      handleMouseEnter,
      handleMouseLeave,
    } = this.props;
    const { color } = this.state;
    var r = color[0][0];
    var g = color[0][1];
    var b = color[0][2];
    var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    const light = hsp > 180.5 ? true : false;
    var textColor = light ? '#000' : '#fff';
    return (
      <div
        key={post.slug + '-' + index}
        className={`animate__animated animate__fadeInUp ${colClass}`}
      >
        <div className={`post ${toogleClass[index]}`}>
          <div className="post__meta">
            <h2>
              <span
                dangerouslySetInnerHTML={createMarkup(post.title.rendered)}
              ></span>
            </h2>
            <h3>
              <span dangerouslySetInnerHTML={createMarkup(post.chapeu)}></span>
            </h3>
          </div>
          <Link to={`/post/${post.slug}`}>
            <div className="post_image_container">
              <img
                crossOrigin={'anonymous'}
                ref={this.imgRef}
                src={post.fimg_url}
                alt={post.slug}
                onLoad={() => {
                  const colorThief = new ColorThief();
                  const img = this.imgRef.current;
                  const palette = colorThief.getPalette(img, 2, 50);
                  this.setState({ color: palette, loaded: true });
                }}
              />
            </div>
          </Link>
          <div
            className="post_buttons"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <Link to={`/post/${post.slug}`}>
              <button
                className="button button--nina button--text-thick button--text-upper button--size-s"
                data-text="Saiba mais"
                data-color="#ff0000"
                style={{
                  '--color': `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`,
                  '--bg': textColor,
                }}
              >
                {'Saiba mais'.split('').map((char) => {
                  if (char === ' ') {
                    return ' ';
                  }
                  return <span>{char}</span>;
                })}
              </button>
            </Link>
            <a href={post.url} target="_blank" rel="noopener noreferrer">
              <button
                className="button button--nina button--text-thick button--text-upper button--size-s"
                data-text="Acesse o site"
                style={{
                  backgroundColor: `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`,
                  color: textColor,
                }}
              >
                {'Acesse o site'.split('').map((char) => {
                  if (char === ' ') {
                    return ' ';
                  }
                  return <span>{char}</span>;
                })}
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Post;
