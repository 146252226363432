import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Axios from 'axios';
import './style.css';
import Nav from './Components/Nav';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import Single from './Pages/Single';
import ScrollToTop from './Components/ScrollToTop';
import Cv from './Pages/Cv';
import Contato from './Pages/Contato';
import _ from 'lodash';

class App extends Component {
  state = {
    posts: [],
    moreLoaded: false,
  };
  componentDidMount() {
    Axios.get(
      `https://viniciusofp.com.br/wp-json/wp/v2/posts?per_page=50&categories=3`
    ).then((res) => {
      var posts = res.data;
      posts = _.orderBy(posts, ['sticky'], ['desc']);
      this.setState({ posts });
    });
  }

  _onLoadMore = () => {
    const postHeight = document.getElementsByClassName('post')[0].offsetHeight;
    console.log(postHeight);
    setTimeout(() => {
      window.scrollBy({ top: postHeight, left: 0, behavior: 'smooth' });
    }, 250);
    const moreLoaded = true;
    this.setState({ moreLoaded });
  };
  render() {
    const { posts } = this.state;
    return (
      <Router>
        <ScrollToTop />
        <div>
          <Nav />
          <Switch>
            <Route path="/post/:slug">
              <Single posts={posts} />
            </Route>
            <Route path="/portfolio">
              <Portfolio posts={posts} />
            </Route>
            <Route path="/cv">
              <Cv />
            </Route>
            <Route path="/contato">
              <Contato />
            </Route>
            <Route path="/">
              <Home
                posts={posts}
                loadMore={this._onLoadMore}
                moreLoaded={this.state.moreLoaded}
              />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
