import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { createMarkup } from '../includes/helperFunctions';
import ColorThief from 'colorthief';

const filterPost = (self, postsArray, slug) => {
  const post = postsArray.filter((post) => {
    return post.slug === slug;
  })[0];
  self.setState({ post });
};
class Single extends Component {
  constructor(props) {
    super(props);

    // Image element reference
    this.imgRef = React.createRef();
  }
  state = { post: { title: {}, content: {} }, color: 'rgba(0,0,0)' };
  componentDidMount() {
    if (this.props.posts.length) {
      filterPost(this, this.props.posts, this.props.match.params.slug);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      filterPost(this, this.props.posts, this.props.match.params.slug);
    }
  }
  render() {
    const { post } = this.state;
    const { color } = this.state;
    var r = color[0][0];
    var g = color[0][1];
    var b = color[0][2];
    var hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    const light = hsp > 180.5 ? true : false;
    var textColor = light ? '#000' : '#fff';

    return this.props.posts.length ? (
      <div className="single  animate__animated animate__fadeIn animate__slow">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="single_meta">
                <Link to="/">
                  <i
                    className="fa fa-arrow-left"
                    style={{
                      color: `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`,
                    }}
                  ></i>
                </Link>
                <h1>{post.title.rendered}</h1>
                <h4>{post.chapeu}</h4>
                <a href={post.url} target="_blank" rel="noopener noreferrer">
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="Acesse o site"
                    style={{
                      backgroundColor: `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`,
                      color: textColor,
                    }}
                  >
                    {'Acesse o site'.split('').map((char, index) => {
                      if (char === ' ') {
                        return ' ';
                      }
                      return <span key={`span-${index}`}>{char}</span>;
                    })}
                  </button>
                </a>
                <div
                  className="single_content"
                  dangerouslySetInnerHTML={createMarkup(post.content.rendered)}
                ></div>
              </div>
            </div>
            <div className="col-md-8 single_image_container">
              <div
                className="sticky-top"
                style={{ backgroundImage: `url(${post.fimg_url})` }}
              ></div>

              <img
                className="d-none"
                crossOrigin={'anonymous'}
                ref={this.imgRef}
                src={post.fimg_url}
                alt=""
                onLoad={() => {
                  const colorThief = new ColorThief();
                  const img = this.imgRef.current;
                  const palette = colorThief.getPalette(img, 2, 50);
                  this.setState({ color: palette, loaded: true });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(Single);
