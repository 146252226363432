import React, { Component } from 'react';

class Contato extends Component {
  state = {};
  render() {
    return (
      <div className="contato">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center align-self-center">
              <h5>
                <strong>Obrigado pela visita!</strong>
              </h5>
              <p>
                Fique a vontade para entrar em contato e me contar sobre seu
                projeto.
              </p>
              <p>
                <strong>Email:</strong> viniciusofp@gmail.com
                <br />
                <strong>WhatsApp:</strong> 11 97697 0327
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contato;
